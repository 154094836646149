"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var core_http_1 = require("@azure/core-http");
var TrouterUtils_1 = require("./TrouterUtils");
var constants_1 = require("./constants");
var defaultSettings = {
    version: "1.0.0",
    registrationId: "",
    sessionId: "",
    pnhAppId: "AcsWeb",
    pnhTemplate: "AcsWeb_Chat_1.6",
    platform: "SPOOL",
    platformUIVersion: "0.0.0",
    environment: "",
    productName: "acs-chat-web",
    trouterServiceUrl: constants_1.PUBLIC_TROUTER_SERVICE_URL,
    registrarServiceUrl: constants_1.PUBLIC_REGISTRAR_SERVICE_URL,
    registrarRefreshTimeoutInMs: 350000,
    timeoutOptions: {
        connectionTimeoutMs: 20000,
        fetchTimeoutMs: 10000,
        pingTimeoutMs: 40000,
        pongTimeoutMs: 5000,
        maxBackoffMs: 50000,
        requestTimeoutMs: 5000
    },
    maxRegistrationTimeInMs: 7200000
};
var createDefaultSettings = function () {
    var settings = defaultSettings;
    settings.registrationId = core_http_1.generateUuid();
    settings.sessionId = core_http_1.generateUuid();
    return settings;
};
var createIntSettings = function () {
    var settings = createDefaultSettings();
    settings.pnhAppId = "cns-e2e-test";
    settings.pnhTemplate = "cns-e2e-test:1.6";
    settings.registrarServiceUrl = constants_1.INT_REGISTRAR_SERVICE_URL;
    settings.trouterServiceUrl = constants_1.INT_TROUTER_SERVICE_URL;
    return settings;
};
var createGccSettings = function () {
    var settings = createDefaultSettings();
    settings.registrarServiceUrl = constants_1.PUBLIC_REGISTRAR_SERVICE_URL;
    settings.trouterServiceUrl = constants_1.GCC_TROUTER_SERVICE_URL;
    return settings;
};
var createGcchSettings = function () {
    var settings = createDefaultSettings();
    settings.registrarServiceUrl = constants_1.GCCHIGH_REGISTRAR_SERVICE_URL;
    settings.trouterServiceUrl = constants_1.GCCHIGH_TROUTER_SERVICE_URL;
    return settings;
};
var createDodSettings = function () {
    var settings = createDefaultSettings();
    settings.registrarServiceUrl = constants_1.DOD_REGISTRAR_SERVICE_URL;
    settings.trouterServiceUrl = constants_1.DOD_TROUTER_SERVICE_URL;
    return settings;
};
var createEudbSettings = function () {
    var settings = createDefaultSettings();
    settings.registrarServiceUrl = constants_1.PUBLIC_REGISTRAR_SERVICE_URL;
    settings.trouterServiceUrl = constants_1.EUDB_TROUTER_SERVICE_URL;
    return settings;
};
exports.createSettings = function (credential, options) { return __awaiter(void 0, void 0, void 0, function () {
    var settings, parsedTokenCredential;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((options === null || options === void 0 ? void 0 : options.environment) === "INT")) return [3 /*break*/, 1];
                settings = createIntSettings();
                return [3 /*break*/, 3];
            case 1: return [4 /*yield*/, TrouterUtils_1.parseTokenCredential(credential)];
            case 2:
                parsedTokenCredential = _b.sent();
                if (parsedTokenCredential.cloudType === constants_1.CloudType.GccHigh) {
                    settings = createGcchSettings();
                }
                else if (parsedTokenCredential.cloudType === constants_1.CloudType.Dod) {
                    settings = createDodSettings();
                }
                else if (parsedTokenCredential.resourceLocation === "usgcc") {
                    settings = createGccSettings();
                }
                else if (parsedTokenCredential.cloudType === constants_1.CloudType.Public &&
                    TrouterUtils_1.isEudbLocation(parsedTokenCredential.resourceLocation)) {
                    settings = createEudbSettings();
                }
                else {
                    settings = createDefaultSettings();
                }
                _b.label = 3;
            case 3:
                settings.maxRegistrationTimeInMs = (_a = options === null || options === void 0 ? void 0 : options.registrationTimeInMs) !== null && _a !== void 0 ? _a : defaultSettings.maxRegistrationTimeInMs;
                return [2 /*return*/, settings];
        }
    });
}); };
exports.defaultTelemetrySettings = {
    // TBD Can we hook up OpenTelemetry?
    enabled: false
};
